<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { useThemeStore } from "@/stores/themeStore";
import { LicenseManager } from "ag-grid-enterprise";
import { useToast } from "vue-toastification";
import c3api from "@/c3api";
import { onBeforeMount, ref } from "vue";
import useBins from "../../../hooks/useBins";
import { useRoute } from "vue-router/auto";
import { useRouter } from "vue-router";
import QuantityCellRenderer from "../../../components/bins/QuantityCellRenderer.vue";
import fileDownload from "js-file-download";
import PalletNameRenderer from "@/components/bins/PalletNameRenderer.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);
const themeStore = useThemeStore();
const toast = useToast();

let locationGridApi;
let lpsGridApi;
const loadCount = ref(0);

const {
  binLoading,
  bin,
  fetchBin,
  updateBin,
  deleteBin,
  locationBreakdowns,
  fetchLocationBreakdowns,
  lps,
  fetchLps,
} = useBins();

const route = useRoute();
const router = useRouter();
const barcodeUrl = ref("");
let fileName = ref("");

onBeforeMount(async () => {
  await fetchBin(route.params.id);
  let fileDetails = await printLabel();
  barcodeUrl.value = fileDetails.url;
  fileName.value = fileDetails.fileName;
});

const onLocationGridReady = async (params) => {
  locationGridApi = params.api;
  await fetchLocationBreakdowns(route.params.id);
  locationGridApi.setGridOption("rowData", locationBreakdowns.value.data);
};

const onLpsGridReady = async (params) => {
  lpsGridApi = params.api;
  await fetchLps(route.params.id);
};

// Location breakdown configs
const locationColumnDefs = [
  { field: "customer_sku", headerName: "SKU", cellDataType: "text", minWidth: 100},
  { field: "pallets", headerName: "PLTS", cellDataType: "number", minWidth: 100 },
  { field: "cases", headerName: "CS", cellDataType: "number", minWidth: 100 },
];

const gridOptions = {
  pagination: false,
  resetRowDataOnUpdate: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
};

// lps setting:

const lpsColumnDefs = [
  { field: "id", headerName: "PLT #", cellDataType: "number" },
  { field: "number", headerName: "LP Number", cellDataType: "text", cellRenderer: PalletNameRenderer },
  { field: "product.sku", headerName: "SKU(s)", cellDataType: "text" },
  { field: "expiry", headerName: "Expiration", cellDataType: "text" },
  { field: "lot", headerName: "Lot Code", cellDataType: "text" },
  {
    field: "pallet_qty",
    headerName: "Qty",
    cellDataType: "number",
    cellRenderer: QuantityCellRenderer,
    autoHeight: true,
    width: 250,
  },
];

const buildQueryParams = function (params) {
  const urlParams = {
    sortModel:
      params.request?.sortModel.length > 0
        ? params.request?.sortModel
        : [{ colId: "id", sort: "desc" }],
    filterModel: params.request?.filterModel,
    startRow: params.request?.startRow,
    endRow: params.request?.endRow,
    batch_size: 100,
    limit: 400,
  };

  const filterParams = {};
  if (params.request?.filterModel) {
    for (let [k, v] of Object.entries(params.request.filterModel)) {
      if (advancedFilterKeys.includes(k)) {
        if (v.filterType === "set") {
          filterParams[k] = {
            filter: v.values,
            type: v.filterType,
          };
        } else {
          filterParams[k] = v;
        }
      } else {
        urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
      }
    }
  }

  urlParams.filter = filterParams;
  if (typeof params.request?.startRow !== "undefined") {
    urlParams.start_row = params.request.startRow;
    urlParams.limit = params.request.endRow - params.request.startRow;
  }

  return urlParams;
};

const connectDatasource = {
  getRows: async (params) => {
    loadCount.value++;
    console.log("getRows called with sortModel:", params.request.sortModel);
    try {
      const urlParams = buildQueryParams(params);
      console.log("Built Query Params:", urlParams);
      lpsGridApi.showLoadingOverlay();
      const response = await fetchLps(route.params.id, urlParams);
      console.log("Response Data:", response.data);
      const data = response.data;

      if (data.length == 0) {
        lpsGridApi.showNoRowsOverlay();
      }

      params.success({
        rowData: data,
        rowCount: response.total_count,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        lpsGridApi.hideOverlay();
      }
    }
  },
  maxConcurrentDatasourceRequests: 1,
};

const advancedFilterKeys = [
  "id",
  "number",
  "product.sku",
  "expiry",
  "lot"
];

const lpsDefaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true
});

const lpGridOptions = {
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  getRowId: (params) => params.data.id,
  onSortChanged: (event) => {
    debounce(() => lpsGridApi.refreshServerSideStore(), 300);
  },
};

const exportPalletsAsCSV = async () => {

  loadCount.value++;
  lpsGridApi.showLoadingOverlay();

  const filterModel = lpsGridApi.getFilterModel();
  let sortModel = lpsGridApi.getColumnState().filter((column) => column.sort);
  sortModel = sortModel.map((item) => ({ colId: item.colId, sort: item.sort }));

  const params = {
    request: {
      filterModel: filterModel,
      sortModel: sortModel
    }
  };

  const urlParams = buildQueryParams(params);
  try {
    const response = await c3api.get(`bins/${route.params.id}/lps_csv`, { params: urlParams });
    const csv_data = response.data;
    const filename =
      response.headers["content-disposition"].match(/filename="?([^"]+)"?/)[1] ||
      "bins_lps_export.csv";
    fileDownload(csv_data, filename);
  } catch (error) {
    console.error(error);
    toast.error(error);
  } finally {
    loadCount.value--;
    if (loadCount.value <= 0) {
      lpsGridApi.hideOverlay();
    }
  }
};

const removeBin = async (id) => {
  await deleteBin(route.params.id)
    .then((res) => {
      fetchBin(route.params.id);
      toast.success("Bin has been successfully deleted.");
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });
};

const getFilenameFromHeader = (response) => {
  let header = response.headers['content-disposition'];
  const regex = /filename="([^"]+)"/;
  const match = header.match(regex);
  let fileName = "";
  if (match) {
    fileName = match[1];
    console.log(fileName);
  } else {
    console.log("Filename not found in the header.");
    fileName = `${route.params.id}_label.pdf`
  }
  return fileName;
}

const printLabel = async () => {

  let response = await c3api.get(`bins/${route.params.id}/label`, { responseType: 'blob' });
  let fileName = getFilenameFromHeader(response);
  const url = URL.createObjectURL(response.data);
  return {url, fileName};
}

const saveNotes = async () => {
  await updateBin(bin.value.id, bin.value);
  toast.success("Note was successfully updated.");
}

</script>

<template>
  <div class="ml-4 d-flex justify-space-between top-section">
    <div class="text-h5 mt-6 ml-2">
      <a :href="'/bins'">Bins</a> / {{bin?.warehouse?.name}} / {{bin?.name}}
    </div>

    <div class="button-section d-flex">
      <v-btn v-if="!bin?.deleted_at" class="mt-4" color="primary" @click="()=> {
        router.push(`/bins/${route.params.id}/edit`)
      }">
        Edit
      </v-btn>
      <v-btn v-if="!bin?.deleted_at" class="mt-4" color="red" @click="removeBin()">
        Delete
      </v-btn>
      <v-btn class="mt-4 mr-8" color="secondary" @click="exportPalletsAsCSV()">
        Export CSV
      </v-btn>

    </div>

  </div>

  <div class="d-flex ml-4 main-content">
    <div class="mt-16 left-panel">

      <v-card class="mr-2">
        <v-card-text class="detail-card">
          <span>
            <p class="text-h5 mb-1">Name:</p>
            <p class="text-subtitle-2">{{ bin?.name }}</p>
          </span>

          <span class="d-flex align-center ga-3">
            <p class="text-h5">Holds Damages:</p>
            <v-checkbox density="compact" class="mb-10 damage-checkbox" v-if="bin" readonly v-model="bin.holds_processable_damage" color="pink" hide-details></v-checkbox>
          </span>

          <span class="d-flex align-center ga-3">
            <p class="text-h5">Holds Destructions:</p>
            <v-checkbox density="compact" class="mb-10 damage-checkbox" v-if="bin" readonly v-model="bin.holds_destructable_damage" color="pink" hide-details></v-checkbox>
          </span>

          <span>
            <p class="text-h5 mb-1">Barcode Data:</p>
            <p class="text-subtitle-2 text-pink-lighten-2">{{ bin?.barcode }}</p>
          </span>

          <span>
            <p class="text-h5 mb-1">Warehouse:</p>
            <p>{{ bin?.warehouse?.name }}</p>
          </span>

          <span>
            <p><a :href="barcodeUrl" target="_blank">Print Label</a></p>
          </span>

          <span>
            <p class="text-h5">Notes:</p>
            <v-textarea v-if="bin" class="mt-4" rows="1" auto-grow v-model="bin.notes"></v-textarea>
            <v-btn class="save-note" color="primary" @click="saveNotes">
              Save
            </v-btn>

          </span>
        </v-card-text>
      </v-card>

      <div class="loc-table mt-6 mb-16">
        <p class="text-h5 text-center mb-6">Location Breakdowns</p>
        <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="locationColumnDefs"
          :gridOptions="gridOptions" @grid-ready="onLocationGridReady">
        </AgGridVue>
          <v-table class="border-t-sm">
            <thead>
              <tr>
                <th class="text-left total-row">
                  Total
                </th>
                <th class="text-left pl-2 total-row">
                  {{locationBreakdowns?.total_pallets}}
                </th>
                <th class="text-center total-row">
                  {{locationBreakdowns?.total_cases}}
                </th>
              </tr>
            </thead>
          </v-table>
      </div>
    </div>
    <div class="mr-2 right-panel">
      <div class="lps-table">
        <p class="text-h5 text-center pt-4 mb-4">Pallets</p>
        <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="lpsColumnDefs"
          :gridOptions="lpGridOptions" :defaultColDef="lpsDefaultColDef" @grid-ready="onLpsGridReady">
        </AgGridVue>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-h5 {
  /*needs to be inside scope different font used in other components */
  font-family: 'Source Sans 3', 'sans-serif' !important;
}

@media (max-width: 768px) {

  .top-section,
  .main-content {
    flex-direction: column;
    gap: 1rem;
  }
}

.damage-checkbox {
  height: 5px;
}

.button-section {
  gap: 1rem;
  float: right;
}

.save-note {
  float: right;
}

.left-panel {
  flex-basis: 25%;
}

.right-panel {
  flex-basis: 75%;
}

.loc-table {
  height: 550px;
  padding-right: 10px;
}

.lps-table {
  height: 1265px;
  padding: 0 10px 10px;
}

.detail-card {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: large;
}
</style>