<script setup>

import {onBeforeMount} from 'vue';

const props = defineProps({});
let palletUrl;
onBeforeMount(() => {
    palletUrl = "/pallet-search/"+props.params.data.id;
});
</script>


<template>
    <p><a :href="palletUrl" target="blank">{{props.params.data.number}}</a></p>
</template>