<template>
  <span class="d-flex justify-space-evenly">
    <div v-if="!showEdit">{{ props.params.data.pallet_qty }}</div>
    <span v-if="showEdit">
      <v-text-field
        label="Qty"
        v-model="qty"
        class="mt-4"
        variant="outlined"
        density="compact"
        type="number"
      ></v-text-field>
      <v-text-field
        label="Notes"
        v-model="notes"
        class="notes-input"
        variant="outlined"
        density="compact"
      ></v-text-field>
      <v-btn size="x-small" class="mb-4" color="primary" @click="handleProcess"
        >Process</v-btn
      >
      <v-btn
        size="x-small"
        class="ml-2 mb-4"
        color="white"
        @click="showEditComponent(false)"
        >Cancel</v-btn
      >
    </span>
    <v-btn
      v-if="!showEdit && userStore.hasPermissionTo('adjust_lp')"
      size="x-small"
      class="ml-4 mb-2 mt-3 mr-16"
      color="primary"
      @click="showEditComponent(true)"
      >Adjust</v-btn
    >
  </span>
</template>

<script setup>
import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

let showEdit = ref(false);
const toast = useToast();

const props = defineProps({
  params: Object,
});

let qty = ref(0);
let notes = ref("");

const showEditComponent = (show) => {
  show ? (showEdit.value = true) : (showEdit.value = false);
};

const handleProcess = async () => {
  if (notes.value == "") {
    toast.error("Please add a note.");
    return;
  }

  const data = {
    qty: qty.value,
    notes: notes.value,
  };

  try {
    const response = await c3api.post(
      `/lps/${props.params.data?.id}/inventory_transactions`,
      data
    );
    props.params.api.refreshServerSide();
    showEdit.value = false;
    toast.success("Quantity adjusted successfully.");
  } catch (error) {
    toast.error(error.response.data.data?.join(". "));
  }
};
</script>
